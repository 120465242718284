import React from "react"
import { FormattedMessage } from "react-intl"
import { featureJson } from '../utils/json/feature.js'
import { Link } from "gatsby"
import { Collapse } from 'antd'

import Seo from '../components/seo'
import Icon from '../components/iconfontCpt'
import Layout from "../components/layout"

import '../style/featureComparison.scoped.scss'

const FeatureComparison = ({ pageContext }) => {
  const { Panel } = Collapse
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  featureJson.features.forEach(item => {
    item.openGauss = item.list.every(function(ele) {
      return !ele.openGauss
    })
    item.mogdbS = item.list.every(function(ele) {
      return !ele.mogdbS
    })
    item.mogdbE = item.list.every(function(ele) {
      return !ele.mogdbE
    })
    item.mogdbC = item.list.every(function(ele) {
      return !ele.mogdbC
    })
  })

  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: FeatureComparison"/>
      <div className="w-banner-container">
        <div className="w-banner-box"><div className="w-banner-text"><FormattedMessage id="featureComparse.pageTitle" /></div></div>
      </div>
      <div className="feature-content">
        <div className="pc-content">
          <div className="head">
            <div className="box key-box">
              <span className="head-t"><FormattedMessage id="featureComparse.feature" /></span>
              <span className="desc"><FormattedMessage id="featureComparse.feature_desc" /></span>
            </div>
            {featureJson.title.map((item, idx) => (
              <div className="box" key={idx}>
                <span className="head-t">{item['type_' + pageLang]}</span>
                <span className="desc">{item['desc_' + pageLang]}</span>
              </div>
            ))}
          </div>
          <div className="list">
            {featureJson.features.map((item, idx) => {
              return (
                <div className="list-box" key={idx}>
                  <div className="item-title">{item['head_' + pageLang]}</div>
                  {item.list.map((ele, index) => {
                    return (
                      <div className="list-item" key={index}>
                        <span className="type-name">{ele['name_' + pageLang]}</span>
                        <span className="icon-font">
                          {ele.openGauss &&
                            <Icon type="icon-success" size={18}/>
                          }
                        </span>
                        <span className="icon-font">
                          {ele.mogdbS &&
                            <Icon type="icon-success" size={18}/>
                          }
                        </span>
                        <span className="icon-font">
                          {ele.mogdbC &&
                            <Icon type="icon-success" size={18}/>
                          }
                        </span>
                        <span className="icon-font">
                          {ele.mogdbE &&
                            <Icon type="icon-success" size={18}/>
                          }
                        </span>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
        <div className="mobail">
          {featureJson.title.map((item, idx) => {
            return (
              <div className="box-container" key={idx}>
                <div className="title">
                  <span className="head-t">{item['type_' + pageLang]}</span>
                  <span className="desc">{item['desc_' + pageLang]}</span>
                </div>
                <Collapse defaultActiveKey={[0]}>
                  {featureJson.features.map((ele, index) => {
                    return (
                      <>
                      {!ele.openGauss && idx === 0 &&
                        <Panel header={ele['head_' + pageLang]} key={index}>
                          {ele.list.map((feature, indexs) => {
                            return (
                              <div className="" key={indexs}>
                                {idx === 0 && feature.openGauss &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 1 && feature.mogdbS &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 2 && feature.mogdbC &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 3 && feature.mogdbE &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                              </div>
                            )
                            })
                          }
                        </Panel>
                      }
                      {!ele.mogdbS && idx === 1 &&
                        <Panel header={ele['head_' + pageLang]} key={index}>
                          {ele.list.map((feature, indexs) => {
                            return (
                              <div className="" key={indexs}>
                                {idx === 0 && feature.openGauss &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 1 && feature.mogdbS &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 2 && feature.mogdbC &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 3 && feature.mogdbE &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                              </div>
                            )
                            })
                          }
                        </Panel>
                      }
                      {!ele.mogdbC && idx === 2 &&
                        <Panel header={ele['head_' + pageLang]} key={index}>
                          {ele.list.map((feature, indexs) => {
                            return (
                              <div className="" key={indexs}>
                                {idx === 0 && feature.openGauss &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 1 && feature.mogdbS &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 2 && feature.mogdbC &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 3 && feature.mogdbE &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                              </div>
                            )
                            })
                          }
                        </Panel>
                      }
                      {!ele.mogdbE && idx === 3 &&
                        <Panel header={ele['head_' + pageLang]} key={index}>
                          {ele.list.map((feature, indexs) => {
                            return (
                              <div className="" key={indexs}>
                                {idx === 0 && feature.openGauss &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 1 && feature.mogdbS &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 2 && feature.mogdbC &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                                {idx === 3 && feature.mogdbE &&
                                  <div className="box">
                                    <span>{feature['name_' + pageLang]}</span>
                                    <span className="icon-font">
                                      <Icon type="icon-success" size={18}/>
                                    </span>
                                  </div>
                                }
                              </div>
                            )
                            })
                          }
                        </Panel>
                      }
                      </>
                    )
                  })}
                </Collapse>
              </div>
            )
          })}

        </div>
        <div className="footer">
          <div className="item-title"><FormattedMessage id="featureComparse.price" /></div>
          <div className="footer-mobail">
            <div className="box key-box">
              <span className="head-t"></span>
              <span className="desc"></span>
            </div>
            <div className="box">
              <span className="head-t">{featureJson.title[0]['type_' + pageLang]}</span>
            </div>
            <div className="box">
              <span className="head-t">{featureJson.title[1]['type_' + pageLang]}</span>
              <span className="head-t">{featureJson.title[2]['type_' + pageLang]}</span>
              <span className="head-t">{featureJson.title[3]['type_' + pageLang]}</span>
            </div>
          </div>
          <div className="list-item">
            <span className="price"><FormattedMessage id="featureComparse.pricingModel" /></span>
            <span className="annual"><FormattedMessage id="featureComparse.annualSupport" /></span>
            <span className="per"><FormattedMessage id="featureComparse.annualSub" /></span>
          </div>
          <div className="list-item">
            <span className="price"><FormattedMessage id="featureComparse.pricingUnit" /></span>
            <span className="annual"><FormattedMessage id="featureComparse.perDatabase" /></span>
            <span className="per"><FormattedMessage id="featureComparse.perCpu" /></span>
          </div>
        </div>
        <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
      </div>
    </Layout>
  )
}
export default FeatureComparison