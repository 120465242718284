const featureJson = {
  title: [
    {
      type_zh: '社区版openGauss支持',
      type_en: 'community(openGauss)',
      desc_zh: '对于数据库技术能力强的企业，可选择社区openGauss数据库和年度数据库维护支持',
      desc_en: 'For organizations with strong database technical ability, can choose community openGauss Database and annual database maintenance support'
    }, {
      type_zh: 'MogDB标准版',
      type_en: 'MogDB(Standard)',
      desc_zh: '适用于需要 Oracle 和 MySQL 的应用程序强兼容性、增强的安全性、或其他企业功能的企业客户',
      desc_en: 'For applications that require Oracle and MySQL compatibility, enhanced security and other enterprise features, using MogDB Standard edition'
    }, {
      type_zh: 'MogDB 容器版',
      type_en: 'MogDB Container',
      desc_zh: '适用于希望在 docker/Kubernetes 获得 MogDB 能力，或为云做好准备的企业客户',
      desc_en: 'for organizations who want to get the  simplified edition of MogDB on docker/Kubernetes or to get cloud-ready'
    }, {
      type_zh: 'MogDB 企业版',
      type_en: 'MogDB Enterprise',
      desc_zh: '适用于需要非常高可用性的关键任务、自动 DR 管理、复杂的架构设计和面向大数据/IOT 的要求的企业客户',
      desc_en: 'for mission critical which  workloads require very high availability, auto DR manage,  complicated architecture design and big data/IOT  oriented requirement '
    }
  ],
  features: [
    {
      head_zh: '技术支持',
      head_en: 'Technical support',
      list: [
        {
          name_zh: '24x7 专家技术支持',
          name_en: '24x7 expert technical support',
          openGauss: true,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '服务期内不限个案次数',
          name_en: 'Unlimited support incidents',
          openGauss: true,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '升级和打补丁服务',
          name_en: 'Upgrade and patching service',
          openGauss: true,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '响应级别：1小时内回复，1个工作日内提供方案',
          name_en: 'Response within half-hour, provide solution or workround solution within 1 work day',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '响应级别：4小时内回复，3个工作日内提供方案',
          name_en: 'Response within 4-hours, provide solution or workround solution within 3 work days.',
          openGauss: true,
          mogdbS: false,
          mogdbC: false,
          mogdbE: false
        }, {
          name_zh: '数据库架构设计和咨询，包括高可用，容灾架构，多数据中心的同步等',
          name_en: 'Architecture design/consulting(One master with multi replica，DR in multiple Data Center) ',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }
      ]
    }, {
      head_zh: '关键内核功能特性',
      head_en: 'Enhanced Core Functions and Features',
      list: [
        {
          name_zh: '增强Oracle兼容性：视图，存储过程，索引，函数等',
          name_en: 'Enhanced compatibility for Oracle ',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '增强MySQL兼容性',
          name_en: 'Enhanced compatibility for MySQL',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '动态数据脱敏',
          name_en: 'Dynamic Data Masking',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: 'PostGIS空间服务插件',
          name_en: 'PostGIS',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '混合行-列存储',
          name_en: 'Hybrid Row-Column Store',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: 'Gstrace-性能分析和故障诊断插件',
          name_en: 'Performance and Fault Diagnosis-gstrace ',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: 'WDR - 负载诊断报告',
          name_en: 'WDR - Workload Diagnosis Repor',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '逻辑复制插件',
          name_en: 'Logical Replication',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '数据备份和恢复',
          name_en: 'Data Backup and Recovery  ',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '数据库审计',
          name_en: 'Database Audit',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '全密态数据库',
          name_en: 'Fully-encrypted Database',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: 'AI 能力组件',
          name_en: 'AI Capabilities',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '(MOT) 内存引擎',
          name_en: '(MOT) storage engine',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '账本数据库机制 （区块链表）',
          name_en: 'Ledger Database ',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '透明数据加密',
          name_en: 'Transparent Data Encryption',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '发布订阅功能',
          name_en: 'Change data Publication-Subscription',
          openGauss: false,
          mogdbS: true,
          mogdbC: true,
          mogdbE: true
        }
      ]
    }, {
      head_zh: '容器化支持',
      head_en: 'Docker and Kubernetes support',
      list: [
        {
          name_zh: 'Docker环境主从和容灾管理',
          name_en: 'Master-Replica DR on docker',
          openGauss: false,
          mogdbS: false,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '自动化部署和回收',
          name_en: 'Automatic deployment/recycling',
          openGauss: false,
          mogdbS: false,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '数据备份和恢复管理',
          name_en: 'Backup and recovery capabilities',
          openGauss: false,
          mogdbS: false,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '企业级高可用管控',
          name_en: 'Enterprise-class high availability',
          openGauss: false,
          mogdbS: false,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '数据库资源监控',
          name_en: 'Monitoring capabilities',
          openGauss: false,
          mogdbS: false,
          mogdbC: true,
          mogdbE: true
        }, {
          name_zh: '精细化资源管理控制',
          name_en: 'Refined resource management and control',
          openGauss: false,
          mogdbS: false,
          mogdbC: true,
          mogdbE: true
        }
      ]
    }, {
      head_zh: '易用管理平台和工具箱',
      head_en: 'Easy O&M portal and toolkit',
      list: [
        {
          name_zh: 'MogHA-高可用管理组件',
          name_en: 'MogHA-High Availability Management',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'BRM -数据备份和恢复管理',
          name_en: 'BRM - Backup and Recovery Manager',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'MDB - 异构数据在线迁移及变更数据同步复制',
          name_en: 'MDB - MogDB Data Bridge',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'MIT - 数据监控工具MogDB InsighT',
          name_en: 'MIT - MogDB InsighT',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'PTK - 自动化部署工具箱',
          name_en: 'PTK - Provisioning ToolKit',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'MTK - 数据迁移工具箱',
          name_en: 'MTK - Migration ToolKit',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'SCA - SQL兼容性分析',
          name_en: 'SCA - SQL Compatibility Analyzer',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'MVD - MogDB 数据验证，异构数据对比工具箱',
          name_en: 'MVD - MogDB Verify Data,Heterogeneous database comparison tool',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'Mogeaver - 图形化开发管理软件',
          name_en: 'Mogeaver - Graphical development and management System',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: 'DBMind 组件',
          name_en: 'DBMind componentization',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: '分布式数据库插件',
          name_en: 'Distributed Plugin',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: '时序数据库插件',
          name_en: 'Time Series Plugin',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }, {
          name_zh: '数据库维护管理参考文档离线版本-PDF格式',
          name_en: 'Offline Documentation for maintenance reference(PDF)',
          openGauss: false,
          mogdbS: false,
          mogdbC: false,
          mogdbE: true
        }
      ]
    }
  ]
}
module.exports = {
  featureJson
}